/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { setCreateNewPropertyCurrentPageThunk, setSearchAnimationDetailsThunk } from "../../../redux-magic/store";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

/* Icons / Illustrations */

import SearchProperty from "../../../public/images/icons/home/banners/search_property.svg";
import PostProperty from "../../../public/images/icons/home/banners/post_property_mini_banner.svg";
import { ArrowForwardOutlined } from "@mui/icons-material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "2rem 0rem 1.5rem 0rem",
}));

const BannerDuoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BannerContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: "row",
	direction: locale === "ar-AE" ? "rtl" : "ltr",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "49%",
	height: "100%",
	minHeight: "5.125rem",
	gap: "0.5rem",
	borderRadius: "16px",
	background: "#fff7f0",
	cursor: "pointer",
	// background:
	// 	locale === "ar-AE"
	// 		? "linear-gradient(90deg, rgba(252, 128, 25, 0.00) 0%, rgba(252, 128, 25, 0.19) 100%)"
	// 		: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.02)",
		},
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "1.25rem",
	padding: "1.25rem 1rem 1.25rem 1.5rem",
}));

const TitleSubtitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.75rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: "#212121",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	color: "#212121",
	fontSize: "1rem",
	lineHeight: "1.25rem",
}));

const BannerDuoDesktopOne = ({
	session,
	dispatch,
	new_listing_url,
	newPropertyData,
	searchanimationsettings,
	searchLocation,
}: {
	session: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	searchLocation: any;
}) => {
	const router = useRouter();

	const localLang = {
		search_property:
			router.locale === "ar-AE"
				? "اعثر على منزل أحلامك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಕನಸಿನ ಮನೆ ಕಂಡುಹಿಡಿಯಿರಿ"
					: "Find your dream home",
		search_property_subtitle:
			router.locale === "ar-AE"
				? "اتصل فورًا، بدون حدود، بدون تسجيل دخول، وكل ذلك مجانًا!"
				: router.locale === "kn-IN"
					? "ತಕ್ಷಣ ಕರೆ ಮಾಡಿ, ಯಾವುದೇ ಮಿತಿ ಇಲ್ಲ, ಲಾಗಿನ್ ಅಗತ್ಯವಿಲ್ಲ, ಎಲ್ಲವೂ ಉಚಿತ!"
					: "Call instantly, no limits, no login, all for free!",
		search_property_action: router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕು" : "Search",
		post_property:
			router.locale === "ar-AE"
				? "قم بنشر عقارك مجانًا!"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಉಚಿತವಾಗಿ ಪ್ರಕಟಿಸಿ!"
					: "Post your property for FREE!",
		post_property_subtitle:
			router.locale === "ar-AE"
				? "بع أو أجر عقارك على منصتنا"
				: router.locale === "kn-IN"
					? "ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಮಾಡಿ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ"
					: "Sell or rent out your property on our platform",
		post_property_action:
			router.locale === "ar-AE" ? "نشر عقارك" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post property",
	};

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const pathname = React.useRef(router).current;

	/*

		* pathname create property trigger state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true) {
			session && session.authorized ? setCreate(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

	* Close state and remove query from url

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true && create === false) {
			window.history.pushState({ urlPath: "/?create_property=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create]);

	const banners = [
		{
			title: localLang.search_property,
			subtitle: localLang.search_property_subtitle,
			icon: SearchProperty,
			button_label: localLang.search_property_action,
		},
		{
			title: localLang.post_property,
			subtitle: localLang.post_property_subtitle,
			icon: PostProperty,
			button_label: localLang.post_property_action,
		},
	];

	const handleBannerClick = (index: number) => {
		if (index === 0) {
			if (!searchLocation) {
				dispatch(
					setSearchAnimationDetailsThunk({
						StartAnimate: searchanimationsettings?.StartAnimate as boolean,
						TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
						ResetSearch: searchanimationsettings?.ResetSearch as boolean,
						PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
						HomeAccordion: false,
						OpenLocationDrawer: true,
					}),
				);
			} else {
				const { title, coordinates } = searchLocation.value;

				const callbackUrl = encodeURIComponent(
					`${process.env.PRODUCTION_URL}search?search_type=properties&location=${title},${coordinates[0]},${coordinates[1]}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${router.locale || "en-In"}`,
				);

				const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

				window.location.href = url;
			}
		} else if (index === 1) {
			session !== null
				? handleCreateOpen()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		}
	};

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
			/>

			<BannerDuoContainer>
				{banners.map((banner, index) => (
					<BannerContainer
						id={"home_mobile_banner_" + banner.title}
						key={index}
						locale={router.locale ? router.locale : "en-IN"}
						onClick={() => {
							handleBannerClick(index);
						}}
					>
						<TextContainer>
							<TitleSubtitleContainer>
								<Title
									variant="h6"
									sx={{
										fontWeight: router.locale === "ar-AE" ? 600 : 500,
									}}
								>
									{banner.title}
								</Title>
								<SubTtext
									sx={{
										textAlign: router.locale === "ar-AE" ? "right" : "left",
									}}
								>
									{banner.subtitle}
								</SubTtext>
							</TitleSubtitleContainer>

							<BeegruButton
								flavor="success"
								variant="outlined"
								size="small"
								sx={{
									margin: "0.25rem 0rem 0rem 0rem",
									"&.MuiButton-outlined": {
										color: "#b05911",
										border: `1px solid #b05911`,
										"&:hover": {
											background: "transparent",
										},
										"&:active": {
											background: "transparent",
										},
										"&:focus": {
											background: "transparent",
										},
										fontSize: "0.825rem",
									},
								}}
								onClick={(e: any) => {
									e.stopPropagation();
									handleBannerClick(index);
								}}
								disableElevation
								disableRipple
								disableFocusRipple
								disableTouchRipple
								disableHoverRipple
								disableInteractive
								disableTouchListener
								disableFocusListener
							>
								{banner.button_label}
								<ArrowForwardOutlined
									sx={{
										margin: "0rem 0rem 0rem 0.25rem",
										fontSize: "1rem",
									}}
								/>
							</BeegruButton>
						</TextContainer>

						<SvgIcon
							component={banner.icon}
							inheritViewBox={false}
							viewBox={index === 0 ? "0 0 226 147" : index === 1 ? "0 0 136 93" : "0 0 136 93"}
							sx={{
								width: index === 0 ? "12rem" : index === 1 ? "11rem" : "10rem",
								height: index === 0 ? "auto" : index === 1 ? "auto" : "auto",
							}}
						/>
					</BannerContainer>
				))}
			</BannerDuoContainer>
		</MainContainer>
	);
};

export default BannerDuoDesktopOne;
