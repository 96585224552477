/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { setCreateNewServiceCurrentPageThunk } from "../../../redux-magic/store";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";
import CreateServiceWithUploadTool from "../../modals/ServiceModals/CreateServiceWithUploadTool";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

/* Icons / Illustrations */

import ExplorePossibilities from "../../../public/images/icons/home/banners/get_into_real_estate.svg";
import PostService from "../../../public/images/icons/home/banners/post_service_new.svg";
import { ArrowForwardOutlined } from "@mui/icons-material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "2rem 0rem 1.5rem 0rem",
}));

const BannerDuoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BannerContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: "row",
	direction: locale === "ar-AE" ? "rtl" : "ltr",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "49%",
	height: "100%",
	minHeight: "5.125rem",
	gap: "0.5rem",
	borderRadius: "16px",
	background: "#fff7f0",
	cursor: "pointer",
	// background:
	// 	locale === "ar-AE"
	// 		? "linear-gradient(90deg, rgba(252, 128, 25, 0.00) 0%, rgba(252, 128, 25, 0.19) 100%)"
	// 		: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.02)",
		},
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "1.25rem",
	padding: "1.25rem 1rem 1.25rem 1.5rem",
}));

const TitleSubtitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.75rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: "#212121",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	color: "#212121",
	fontSize: "1rem",
	lineHeight: "1.25rem",
}));

const BannerDuoDesktopTwo = ({
	session,
	dispatch,
	new_listing_url,
	newServiceData,
}: {
	session: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const router = useRouter();

	const localLang = {
		real_estate_marketplace:
			router.locale === "ar-AE"
				? "سوق العقارات"
				: router.locale === "kn-IN"
					? "ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಮಾರುಕಟ್ಟೆ"
					: "Real estate marketplace",
		real_estate_marketplace_subtitle:
			router.locale === "ar-AE"
				? "بسِّط رحلتك العقارية مع بيجرو"
				: router.locale === "kn-IN"
					? "ಬೀಗ್ರು ಸಹಿತ ನಿಮ್ಮ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಪ್ರಯಾಣವನ್ನು ಸುಲಭಗೊಳಿಸಿ"
					: "Simplify your real estate journey with Beegru",
		real_estate_marketplace_action:
			router.locale === "ar-AE" ? "اعرف المزيد" : router.locale === "kn-IN" ? "ಹೆಚ್ಚು ತಿಳಿಯಿರಿ" : "Know more",
		post_service:
			router.locale === "ar-AE"
				? "قم بنشر خدمتك مجانًا!"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಉಚಿತವಾಗಿ ಪ್ರಕಟಿಸಿ!"
					: "Post your service for FREE!",
		post_service_subtitle:
			router.locale === "ar-AE"
				? "احصل على وصول ورؤية أفضل لخدماتك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸೇವೆಗಳಿಗೆ ಉತ್ತಮ ಪ್ರವೇಶ ಮತ್ತು ದೃಶ್ಯತೆ ಪಡೆಯಿರಿ"
					: "Get better reach & visibility for your services",
		post_service_action:
			router.locale === "ar-AE"
				? "نشر خدمتك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
					: "Post service",
	};

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("business_profile"));
		setOpenService(true);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	const pathname = React.useRef(router).current;

	/*

		* pathname create service trigger service state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true) {
			session && session.authorized ? setOpenService(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

	* Close state and remove query from url

	*/

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true && openservice === false) {
			window.history.pushState({ urlPath: "/?create_service=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openservice]);

	const banners = [
		{
			title: localLang.real_estate_marketplace,
			subtitle: localLang.real_estate_marketplace_subtitle,
			icon: ExplorePossibilities,
			button_label: localLang.real_estate_marketplace_action,
		},
		{
			title: localLang.post_service,
			subtitle: localLang.post_service_subtitle,
			icon: PostService,
			button_label: localLang.post_service_action,
		},
	];

	const handleBannerClick = (index: number) => {
		if (index === 0) {
			window.location.href = "loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro");
		} else if (index === 1) {
			session !== null
				? handleOpenNewService()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		}
	};

	return (
		<MainContainer>
			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			<BannerDuoContainer>
				{banners.map((banner, index) => (
					<BannerContainer
						id={"home_mobile_banner_" + banner.title}
						key={index}
						locale={router.locale ? router.locale : "en-IN"}
						onClick={() => {
							handleBannerClick(index);
						}}
					>
						<TextContainer>
							<TitleSubtitleContainer>
								<Title
									variant="h6"
									sx={{
										fontWeight: router.locale === "ar-AE" ? 600 : 500,
									}}
								>
									{banner.title}
								</Title>
								<SubTtext
									sx={{
										textAlign: router.locale === "ar-AE" ? "right" : "left",
									}}
								>
									{banner.subtitle}
								</SubTtext>
							</TitleSubtitleContainer>

							<BeegruButton
								flavor="success"
								variant="outlined"
								size="small"
								sx={{
									margin: "0.25rem 0rem 0rem 0rem",
									"&.MuiButton-outlined": {
										color: "#b05911",
										border: `1px solid #b05911`,
										"&:hover": {
											background: "transparent",
										},
										"&:active": {
											background: "transparent",
										},
										"&:focus": {
											background: "transparent",
										},
										fontSize: "0.825rem",
									},
								}}
								onClick={(e: any) => {
									e.stopPropagation();
									handleBannerClick(index);
								}}
								disableElevation
								disableRipple
								disableFocusRipple
								disableTouchRipple
								disableHoverRipple
								disableInteractive
								disableTouchListener
								disableFocusListener
							>
								{banner.button_label}
								<ArrowForwardOutlined
									sx={{
										margin: "0rem 0rem 0rem 0.25rem",
										fontSize: "1rem",
									}}
								/>
							</BeegruButton>
						</TextContainer>

						<SvgIcon
							component={banner.icon}
							inheritViewBox={false}
							viewBox={index === 0 ? "0 0 141 71" : index === 1 ? "0 0 128 109" : "0 0 128 109"}
							sx={{
								width: index === 0 ? "13rem" : index === 1 ? "10rem" : "10rem",
								height: index === 0 ? "auto" : index === 1 ? "auto" : "auto",
							}}
						/>
					</BannerContainer>
				))}
			</BannerDuoContainer>
		</MainContainer>
	);
};

export default BannerDuoDesktopTwo;
