/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { setCreateNewPropertyCurrentPageThunk, setSearchAnimationDetailsThunk } from "../../../redux-magic/store";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

/* Icons / Illustrations */

import SearchProperty from "../../../public/images/icons/home/banners/search_property.svg";
import PostProperty from "../../../public/images/icons/home/banners/post_property_mini_banner.svg";
import { ArrowForwardOutlined } from "@mui/icons-material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const CarouselComponent = styled(Carousel)(({ theme }) => ({
	width: "100%",
	height: "100%",
	position: "relative",
	gap: "0.5rem",
	"& .carousel .control-dots": {
		position: "absolute",
		bottom: "0.25rem", // Move dots below the banner
		left: "50%",
		transform: "translateX(-50%)",
		margin: "0rem 0rem 0rem 0rem",
	},
	"& .carousel .control-dots .dot": {
		backgroundColor: "#d9d9d9",
		opacity: 1,
		margin: "0rem 0.25rem 0rem 0.25rem",
		boxShadow: "none",
		height: "0.5rem",
		width: "0.5rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: "#b05911",
		height: "0.5rem",
		width: "1.25rem",
		borderRadius: "0.5rem",
	},
	"& .carousel .slide": {
		textAlign: "left",
	},
}));

const CarouselCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
}));

const BannerContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: "row",
	direction: locale === "ar-AE" ? "rtl" : "ltr",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	minHeight: "5.125rem",
	height: "auto",
	gap: "0.5rem",
	margin: "0rem 0rem 2rem 0rem",
	borderRadius: "16px",
	background: "#fff7f0",
	// background:
	// 	locale === "ar-AE"
	// 		? "linear-gradient(90deg, rgba(252, 128, 25, 0.00) 0%, rgba(252, 128, 25, 0.19) 100%)"
	// 		: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	padding: "1rem 1rem 1rem 1rem",
}));

const TitleSubtitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.25rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
	fontWeight: 500,
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.95rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const BannerCarouselOne = ({
	session,
	dispatch,
	new_listing_url,
	newPropertyData,
	searchanimationsettings,
	searchLocation,
}: {
	session: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	searchLocation: any;
}) => {
	const router = useRouter();

	const localLang = {
		search_property:
			router.locale === "ar-AE"
				? "اعثر على منزل أحلامك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಕನಸಿನ ಮನೆ ಕಂಡುಹಿಡಿಯಿರಿ"
					: "Find your dream home",
		search_property_subtitle:
			router.locale === "ar-AE"
				? "اتصل فورًا، بدون حدود، بدون تسجيل دخول، وكل ذلك مجانًا!"
				: router.locale === "kn-IN"
					? "ತಕ್ಷಣ ಕರೆ ಮಾಡಿ, ಯಾವುದೇ ಮಿತಿ ಇಲ್ಲ, ಲಾಗಿನ್ ಅಗತ್ಯವಿಲ್ಲ, ಎಲ್ಲವೂ ಉಚಿತ!"
					: "Call instantly, no limits, no login, all for free!",
		search_property_action: router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕು" : "Search",
		post_property:
			router.locale === "ar-AE"
				? "قم بنشر عقارك مجانًا!"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಉಚಿತವಾಗಿ ಪ್ರಕಟಿಸಿ!"
					: "Post your property for FREE!",
		post_property_subtitle:
			router.locale === "ar-AE"
				? "بع أو أجر عقارك على منصتنا"
				: router.locale === "kn-IN"
					? "ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಮಾಡಿ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ"
					: "Sell or rent out your property on our platform",
		post_property_action:
			router.locale === "ar-AE" ? "نشر عقارك" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post property",
	};

	const [autoplay, setAutoplay] = React.useState(true);

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const pathname = React.useRef(router).current;

	/*

		* pathname create property trigger state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true) {
			session && session.authorized ? setCreate(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

		* Close state and remove query from url

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true && create === false) {
			window.history.pushState({ urlPath: "/?create_property=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create]);

	const banners = [
		{
			title: localLang.search_property,
			subtitle: localLang.search_property_subtitle,
			icon: SearchProperty,
			button_label: localLang.search_property_action,
		},
		{
			title: localLang.post_property,
			subtitle: localLang.post_property_subtitle,
			icon: PostProperty,
			button_label: localLang.post_property_action,
		},
	];

	const handleBannerClick = (index: number) => {
		if (index === 0) {
			if (!searchLocation) {
				dispatch(
					setSearchAnimationDetailsThunk({
						StartAnimate: searchanimationsettings?.StartAnimate as boolean,
						TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
						ResetSearch: searchanimationsettings?.ResetSearch as boolean,
						PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
						HomeAccordion: false,
						OpenLocationDrawer: true,
					}),
				);
			} else {
				const { title, coordinates } = searchLocation.value;

				const callbackUrl = encodeURIComponent(
					`${process.env.PRODUCTION_URL}search?search_type=properties&location=${title},${coordinates[0]},${coordinates[1]}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${router.locale || "en-In"}`,
				);

				const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

				window.location.href = url;
			}
		} else if (index === 1) {
			session !== null
				? handleCreateOpen()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		}
	};

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
			/>

			<CarouselComponent
				autoPlay={autoplay}
				showArrows={false}
				showThumbs={false}
				swipeable={true}
				showStatus={false}
				infiniteLoop={true}
				interval={5000}
				showIndicators={true}
				onSwipeStart={() => setAutoplay(false)}
				onSwipeEnd={() => setAutoplay(true)}
				transitionTime={500}
			>
				{banners.map((banner, index) => (
					<CarouselCard
						id={"home_mobile_banner_" + banner.title}
						key={index}
						onClick={() => {
							handleBannerClick(index);
						}}
					>
						<BannerContainer locale={router.locale ? router.locale : "en-IN"}>
							<TextContainer>
								<TitleSubtitleContainer>
									<Title
										variant="h6"
										sx={{
											fontWeight: router.locale === "ar-AE" ? 600 : 500,
										}}
									>
										{banner.title}
									</Title>
									<SubTtext
										sx={{
											textAlign: router.locale === "ar-AE" ? "right" : "left",
										}}
									>
										{banner.subtitle}
									</SubTtext>
								</TitleSubtitleContainer>

								<BeegruButton
									flavor="success"
									variant="outlined"
									size="small"
									sx={{
										margin: "0.25rem 0rem 0rem 0rem",
										"&.MuiButton-outlined": {
											color: "#b05911",
											border: `1px solid #b05911`,
											"&:hover": {
												background: "transparent",
											},
											"&:active": {
												background: "transparent",
											},
											"&:focus": {
												background: "transparent",
											},
											fontSize: "0.75rem",
										},
									}}
									onClick={(e: any) => {
										e.stopPropagation();
										handleBannerClick(index);
									}}
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									disableHoverRipple
									disableInteractive
									disableTouchListener
									disableFocusListener
								>
									{banner.button_label}
									<ArrowForwardOutlined
										sx={{
											margin: "0rem 0rem 0rem 0.25rem",
											fontSize: "1rem",
										}}
									/>
								</BeegruButton>
							</TextContainer>

							<SvgIcon
								component={banner.icon}
								inheritViewBox={false}
								viewBox={index === 0 ? "0 0 226 147" : index === 1 ? "0 0 136 93" : "0 0 136 93"}
								sx={{
									width: index === 0 ? "8rem" : index === 1 ? "7rem" : "6rem",
									height: index === 0 ? "auto" : index === 1 ? "auto" : "auto",
								}}
							/>
						</BannerContainer>
					</CarouselCard>
				))}
			</CarouselComponent>
		</MainContainer>
	);
};

export default BannerCarouselOne;
